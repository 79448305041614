import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layout/layout'


export default ({data}) => {
    const post = data.markdownRemark;
    return (
        <Layout>
            <header className="post-header">
                <h1 className="post-title">{post.frontmatter.title}</h1>
                <p className="post-meta">{post.frontmatter.date}</p>
            </header>
            <article className="post-content" dangerouslySetInnerHTML = {{ __html: post.html }} />
        </Layout>
    );
};

export const query = graphql`query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug }}) {
        html
        frontmatter {
            title
            date
        }
    }
}`